<script>
  import { get, call } from 'vuex-pathify'
  import { ApiError } from '@/api'

  export default {
    data() {
      return {
        formRef: null,
        addressId: parseInt(this.$route.params.addressId),
      }
    },
    computed: {
      ...get('account', ['addresses']),
      address() {
        return this.addresses?.find((address) => address.id === this.addressId)
      },
    },
    methods: {
      ...call('account', ['removeAddress']),
      async submit() {
        try {
          await this.formRef.process(this.removeAddress(this.address.id))
          this.$navigate({ name: 'AccountProfileSettings' })
        } catch (error) {
          if (error instanceof ApiError) {
            return
          }
          return Promise.reject(error)
        }
      },
    },
  }
</script>

<template>
  <section>
    <BaseForm v-slot="{ submitEnabled, errorMessage }" v-ref="formRef" spinner @submit="submit">
      <BaseHeading size="h5" tag="h2" class="mb-6"> Remove Shipping Address </BaseHeading>

      <BasePanelWarning v-if="errorMessage" class="mb-6">
        {{ errorMessage }}
      </BasePanelWarning>

      <BaseAddress v-if="address" :address="address" />

      <BaseButton type="submit" :disabled="!submitEnabled" class="mt-6">
        <slot name="buttonLabel">Remove Address</slot>
      </BaseButton>
    </BaseForm>

    <div class="mt-6 -mb-2 flex justify-center">
      <BaseLinkStyled :to="{ name: 'AccountProfileSettings' }" color="gray" font-size="xs">
        Cancel
      </BaseLinkStyled>
    </div>
  </section>
</template>
